import "@css/app.css";
import "@splidejs/splide/css";

import "lazysizes";

import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

import Splide from "@splidejs/splide";

new Splide(".js-carousel", {
  pagination: false,
}).mount();

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
